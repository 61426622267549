<style scoped lang='scss'>
.title{ font-size: $Size30; margin-bottom: 80px;margin-top: 100px; }
#feedback{
  .feedback{
    width: 1000px;
    margin: 0 auto;
  }
  .form{
    width: 100%;
    .formtitle{
      font-size:  $Size14;
      color: $footerColor666;
      line-height: 24px;
      margin-bottom: 70px;
      a,a:hover,a:active,a:focus,a:visited{
        color: #1a90e2;
      }
    }
    .inputEmail{
      width: 309px;
    }
    .eare{
      width: 100%;
      height: 175px;
      .el-textarea__inner{

      }
    }
  }

}
.successModal{
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 8px;
  color: white;
  line-height: 40px;
  font-size: $Size16;
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 50px);
  background-color: rgba($color: #000000, $alpha: 0.8);
}


</style>

<template>
  <div id="feedback">
    <!-- 人才招聘--->
    <div class="width rullMain">
      <div class="feedback">
        <div class="title textCenter weight">网站问题反馈</div>
        <div class="form">
          <div class="formtitle">
            <div>果果慧非常重视自身产品及业务系统的安全问题，我们欢迎广大用户向我们反馈相关产品及业务系统的安全漏洞，以帮助我们提升产品以及业务系统的安全性。请您通过以下方式反馈：发送邮件到漏洞接受专用邮箱：<a href="javascript:;">hr@aifrutech.com</a></div>
            <div>如果您发现漏洞，未经果果慧同意，请不要公开和传播，包括提交到其他漏洞平台。我们承诺会对每一份报告进行评估、跟进和及时反馈处理结果。</div>
          </div>
          <el-form :model="formInline" ref="formInline" :rules="rules" class="demo-form-inline">
            <el-form-item prop="email">
              <el-input class="inputEmail" v-model="formInline.email" placeholder="您的电子邮箱*"></el-input>
            </el-form-item>
            <el-form-item prop="url">
              <el-input class="inputUrl" v-model="formInline.url" placeholder="请您提供发现错误的链接地址*"></el-input>
            </el-form-item>
            <el-form-item prop="desc">
              <el-input class="eare noborderRadio" type="textarea" v-model="formInline.desc" placeholder="描述您的问题"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="btn_concat" type="primary" @click="onSubmit('formInline')">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 成功提示弹窗 -->
      <div v-if="successModal" class="successModal">提交成功!</div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'feedback',
  components: {},
  data () {
    return {
      formInline: {
        email: '',
        url: '',
        desc: ''
      },
      rules: {
        email: [
          { required: true, message: '请输入您的电子邮箱', trigger: 'change' },
        ],
        url: [
          { required: true, message: '请输入有问题的连接地址', trigger: 'change' }
        ],
        desc: [
          { required: false, message: '请填写意见或建议', trigger: 'blur' }
        ]
      },
      // 弹窗提示
      successModal:false,
    }
  },
  computed: {},
  created () {},
  mounted(){
    window.scrollTo({top: 0,left: 0});
  },
  methods: {
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    },
    onSubmit(formName) {
      let _that = this;
      _that.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          const loading = _that.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          setTimeout(() => {
            loading.close();
            _that.$refs[formName].resetFields();
            _that.successModal = true;
          }, 2000);
          setTimeout(()=>{
            _that.successModal = false;
          },3500)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },

}

</script>
